import PropTypes from "prop-types";
import React from "react";

import TextInput from "../../../shared/Input/TextInput";
import Form from "../../../shared/Form";
import { SubmitBtn } from "../Button";
import { PanelTitle } from "../PanelTitle";
import { CheckedSVG } from "../../../shared/svg";

class PasswordForm extends Form {
  render() {
    const { password = "", password_confirmation = "" } = this.props.state.data;
    return (
      <div className="form-group password ">
        <PanelTitle htmlFor="password" title="パスワードの変更" />
        <form className="reset-pw-popup" onSubmit={this.submit}>
          {/* eslint-disable-next-line */}
          <div className="grid-row">
            <TextInput
              type="password"
              name="new-password"
              label="新しいパスワード 6～70文字"
              value={password}
              onChange={this.changeField("password")}
              errors={this.filterValidationErrors("password")}
            />
            <TextInput
              type="password"
              name="new-password-confirmation"
              label="新しいパスワードの確認 6～70文字"
              value={password_confirmation}
              onChange={this.changeField("password_confirmation")}
              errors={this.filterValidationErrors("password_confirmation")}
            />
          </div>
          {this.renderFormError()}
          <div className="row flex-end">
            <SubmitBtn disabled={this.props.state.ui.fetching} />
          </div>
          {this.props.state.ui.showingSuccessMessage && (
            <div className="add-success-message mt-24 flex-colunm-center">
              <div className="flex-row-center align-center">
                <CheckedSVG color="#439F7A" />
                <p className="title">パスワードの変更に成功しました</p>
              </div>
              <p>新しいパスワードをご利用いただけるようになりました。</p>
            </div>
          )}
        </form>
      </div>
    );
  }
}

PasswordForm.propTypes = {
  state: PropTypes.object,
  open: PropTypes.func,
  close: PropTypes.func,
  submit: PropTypes.func,
  changeField: PropTypes.func,
};

export default PasswordForm;
