import React, { useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import cx from "classnames";

import { CHECKOUT_CONFIRMATION_PATH } from "../../constants/Paths";
import { CHECKOUT, CHECKOUT_AGREEMENT } from "../../constants/Texts";

import { pushData } from "../UploadTransactionData";

import { CartSummary } from "./CartSummary";
import { scrollToFirstError } from "./scrollToFirstError";

import { UserError } from "./Errors/UserError";
import { AddressInCompleteError } from "../shared/Errors";
import { CombiniPickUpError } from "./Errors/CombiniPickUpError";
import {
  DeliveryDateError,
  DeliveryHoursError,
} from "./Errors/DeliveryDateError";
import { OrderNotFreshError } from "./Errors/OrderNotFreshError";

import { OrderServiceProps, DataProps, CartProps } from "../../orders";
import { theplant } from "../../proto";

import { UploadConfirmOrderToGTM } from "../../gtm/GA";

const PaymentType = theplant.ec.service.orders.PaymentType;

interface CartSummaryAndConfirmButtonProps {
  isAmazonPayInComplete?: boolean;
  cart: CartProps["cart"]["cart"];
  checkout: OrderServiceProps["checkout"];
  data: DataProps["data"];
  confirmAmazonOrder?: () => void;
}

type Props = CartSummaryAndConfirmButtonProps & RouteComponentProps<{}>;

const ConfirmOrderError = ({
  checkout: { error },
  isAmazonPayInComplete,
}: Pick<Props, "checkout" | "isAmazonPayInComplete">) => (
  <>
    <UserError />
    <OrderNotFreshError error={error} />
    {!isAmazonPayInComplete && (
      <>
        <CombiniPickUpError />
        <DeliveryDateError />
        <DeliveryHoursError />
      </>
    )}
    {isAmazonPayInComplete && <AddressInCompleteError error={error} />}
  </>
);

const _CartSummaryAndConfirmButton = (props: Props) => {
  const {
    isAmazonPayInComplete,
    cart,
    history,
    data: dataProp,
    checkout: {
      confirm,
      input: { state, form },
    },
    confirmAmazonOrder,
  } = props;

  const isThirdPay = useMemo(() => {
    if (isAmazonPayInComplete) return false;
    return [PaymentType.AMAZON_PAY, PaymentType.SMART_PAY].includes(
      Number(form.paymentType)
    );
  }, [form.paymentType, isAmazonPayInComplete]);

  if (!dataProp) {
    return null;
  }

  return (
    <div>
      <CartSummary
        cart={cart}
        checkout={props.checkout}
        data={dataProp.data}
        isAmazonPay={isAmazonPayInComplete}
      />

      <div className="confirm-btn-wrapper">
        {isThirdPay ? null : (
          <button
            disabled={state === "validating"}
            className={cx("primary-btn confirm-order-btn", {
              loading: state === "validating",
            })}
            onClick={() => {
              UploadConfirmOrderToGTM({
                order: cart,
                data: dataProp.data,
                checkout: form,
              });
              if (isAmazonPayInComplete) {
                if (confirmAmazonOrder) {
                  // call confirm api in amazon pay confirm button
                  confirmAmazonOrder();
                }
              } else {
                confirm((order) => {
                  // the GTM trigger is listening on history change,
                  // need to set the data before history change,
                  // otherwise the GA Transaction Tag cannot work.
                  pushData(order || {});

                  history.replace(CHECKOUT_CONFIRMATION_PATH);
                }, scrollToFirstError);
              }
            }}
          >
            {CHECKOUT}
          </button>
        )}

        <ConfirmOrderError
          checkout={props.checkout}
          isAmazonPayInComplete={isAmazonPayInComplete}
        />

        <p>{CHECKOUT_AGREEMENT}</p>
      </div>
    </div>
  );
};

const CartSummaryAndConfirmButton = withRouter(_CartSummaryAndConfirmButton);

export { CartSummaryAndConfirmButton, ConfirmOrderError };
