import PropTypes from "prop-types";
import React from "react";

import TextInput from "../../../shared/Input/TextInput";
import Form from "../../../shared/Form";
import { SubmitBtn } from "../Button";
import { PanelTitle } from "../PanelTitle";
import { CheckedSVG } from "../../../shared/svg";
import Divider from "../../../molecules/Divider";

class EmailForm extends Form {
  render() {
    const { showingSuccessMessage: success } = this.props.state.ui;
    const { email = "", email_confirmation = "" } = this.props.state.data;

    return (
      <>
        <PanelTitle
          htmlFor="email"
          title="メールアドレスアドレスの変更"
          subTitle={`利用中のメールアドレス：${this.props.email}`}
        />
        <Divider />
        <form className="reset-email-popup" onSubmit={this.submit}>
          {/* eslint-disable-next-line */}
          <div className="grid-row">
            <TextInput
              name="new-email"
              label="新しいメールアドレス"
              value={email}
              onChange={this.changeField("email")}
              errors={this.filterValidationErrors("email")}
            />
            <TextInput
              name="new-email-confirmation"
              label="新しいメールアドレスの確認"
              value={email_confirmation}
              onChange={this.changeField("email_confirmation")}
              errors={this.filterValidationErrors("email_confirmation")}
            />
          </div>
          {this.renderFormError()}
          <div className="flex flex-end">
            <SubmitBtn disabled={this.props.state.ui.fetching} />
          </div>

          {success && (
            <div className="add-success-message mt-24 flex-colunm-center">
              <div className="flex-row-center align-center">
                <CheckedSVG color="#439F7A" />
                <p className="title">メールをご確認ください</p>
              </div>
              <p>
                メールアドレス変更の確認メールを送信しました。メールの中のリンクをクリックするとアドレス変更が確定されます。
              </p>
            </div>
          )}
        </form>
      </>
    );
  }
}

EmailForm.propTypes = {
  email: PropTypes.string,
  state: PropTypes.object,
  open: PropTypes.func,
  close: PropTypes.func,
  submit: PropTypes.func,
  changeField: PropTypes.func,
};

export default EmailForm;
