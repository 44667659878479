import PropTypes from "prop-types";
import React from "react";

import Form from "../../../shared/Form";
import { TextInput, SelectInput, CheckboxInput } from "../Common";

import { renderIssuerIcon } from "../../../shared/CreditCardIssuer";

import { pad } from "../../../helpers/common";
import { BRANDS } from "../../../../constants/Misc";
import { getCreditCardError } from "../../../../CheckoutApp/shared/getCreditCardError";
import { SubmitBtn, CancelBtn } from "../Button";
import { PanelTitle } from "../PanelTitle";

const initialState = {
  data: {},
  ui: {
    isOpen: false,
    fetching: false,
    error: null,
    applyError: null,
  },
};

const _getExpiryMonthOptions = () => {
  let options = [];
  for (let m = 1; m < 13; m++) {
    options.push([m, pad(m, 2)]);
  }

  return options;
};

const _getExpiryYearOptions = () => {
  let options = [];

  const today = new Date();
  let currentYear = today.getUTCFullYear();

  for (let i = 0; i < 10; i++) {
    let year = currentYear + i;
    options.push([year, pad(year, 4)]);
  }

  return options;
};

const CreditCardIssuers = () => (
  <div className="card-icons flex">
    <ul>
      {BRANDS.map((brand, idx) => (
        <li key={idx} htmlFor={brand}>
          {renderIssuerIcon(brand)}
        </li>
      ))}
    </ul>
  </div>
);

class CreditCardForm extends Form {
  constructor(props) {
    super(props);

    this.expiryMonthOptions = _getExpiryMonthOptions();
    this.expiryYearOptions = _getExpiryYearOptions();
  }

  _renderIsSavingCheckbox() {
    const { withIsSavingCheckbox } = this.props;
    const { isSavingCreditCard = false } = this.props.state.data;

    return withIsSavingCheckbox ? (
      <div className="form-group subscription">
        <CheckboxInput
          label="クレジットカード情報を保存"
          checked={isSavingCreditCard}
          onChange={this.changeField("isSavingCreditCard")}
        />
      </div>
    ) : null;
  }

  render() {
    const {
      name = "",
      number = "",
      expiry_year = "",
      expiry_month = "",
      cvc = "",
    } = this.props.state.data;

    const { error } = this.props.state.ui;

    let errorResponseField, errorMsg;
    if (error) {
      const { responseField, msg } = getCreditCardError(
        [{ msg: error.error }],
        (cvc || "").replace(/[^\d]/g)
      );

      errorResponseField = responseField;
      errorMsg = msg;
    }

    return (
      <div>
        <div className="grid-row grid-gap">
          <PanelTitle title={this.props.title || "クレジットカードを追加"} />
          <CreditCardIssuers />
        </div>
        <form onSubmit={this.submit} className="credit-card-form">
          <div className="grid-row">
            <TextInput
              label="カード名義（ローマ字）"
              name="credit-card-name"
              placeholder="例）TARO YAMADA"
              optional={true}
              value={name}
              onChange={this.changeField("name")}
              errors={
                errorResponseField === "cardholder_name"
                  ? [error.error]
                  : this.filterValidationErrors("name")
              }
              msg={errorMsg}
            />
            <TextInput
              label="クレジットカード番号"
              name="credit-card"
              type="tel"
              placeholder="例）4242 4242 4242 4242"
              value={number}
              onChange={this.changeField("number")}
              errors={
                errorResponseField === "card_number"
                  ? [error.error]
                  : this.filterValidationErrors("number")
              }
              msg={errorMsg}
            />
          </div>
          <label htmlFor="valid-date" className="input-label mt-24">
            有効期限
            <span className="required-icon">※</span>
          </label>
          <div className="form-group valid-date grid-row">
            <div className="grid-row-2-only">
              <SelectInput
                name="valid-date-month"
                value={expiry_month}
                onChange={this.changeField("expiry_month")}
                options={this.expiryMonthOptions}
                label="月"
                errors={this.filterValidationErrors("expiry_month")}
                className="expire-month-field"
                placeholder={{
                  value: "",
                  coveredValue: "月",
                  position: 0,
                }}
              />
              <SelectInput
                name="valid-date-year"
                value={expiry_year}
                onChange={this.changeField("expiry_year")}
                options={this.expiryYearOptions}
                label="年"
                errors={this.filterValidationErrors("expiry_year")}
                className="expire-year-field"
                placeholder={{
                  value: "",
                  coveredValue: "年",
                  position: 0,
                }}
              />
            </div>
            <TextInput
              label="セキュリティコード"
              name="credit-card-cvc"
              placeholder="例）123"
              maxLength={4}
              optional={false}
              value={cvc.replace(/[^\d]/g, "") || ""}
              onChange={this.changeField("cvc")}
              errors={
                errorResponseField === "card_conf_number"
                  ? [error.error]
                  : this.filterValidationErrors("cvc")
              }
              msg={errorMsg}
            />
          </div>
          <div className="row">
            {errorResponseField === "card_valid_term"
              ? this.renderFormError(errorMsg)
              : null}
          </div>
          {this._renderIsSavingCheckbox()}
          {errorResponseField !== "card_number" &&
          errorResponseField !== "cardholder_name" &&
          errorResponseField !== "card_conf_number" &&
          errorResponseField !== "card_valid_term"
            ? this.renderFormError(errorMsg)
            : null}
          <div
            className={`flex ${
              this.props.addMore ? "flex-end" : "justify-center"
            } mt-15`}
          >
            {this.props.close && <CancelBtn close={this.props.close} />}
            <SubmitBtn disabled={this.props.state.ui.fetching} />
          </div>
        </form>
      </div>
    );
  }
}

CreditCardForm.defaultProps = {
  state: { ...initialState },
  withIsSavingCheckbox: true,
};

CreditCardForm.propTypes = {
  state: PropTypes.object,
  changeField: PropTypes.func,
  submit: PropTypes.func,
  withIsSavingCheckbox: PropTypes.bool,
  title: PropTypes.string,
  close: PropTypes.func,
  addMore: PropTypes.bool,
};

export default CreditCardForm;

export {
  _getExpiryMonthOptions,
  _getExpiryYearOptions,
  initialState,
  CreditCardIssuers,
};
