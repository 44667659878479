import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import VideoApp from "./VideoApp";
import { changePlainLinkToEmbed, getVideoInfoFromDom } from "./videoHelper";
import { setHeaderColor } from "./setHeaderTheme";

const VideoLink = ({
  videoUrl,
  mute,
  autoplay,
  videoId,
  device,
  index,
  proportion,
}) => {
  //  user fill an iframe in admin
  if (videoUrl.startsWith("<iframe")) {
    return <div dangerouslySetInnerHTML={{ __html: videoUrl }} />;
  }

  const { url, videoType } = changePlainLinkToEmbed(videoUrl);

  return (
    <VideoApp
      className={`${device}-${index}`}
      url={url}
      videoId={videoId}
      autoplay={autoplay}
      mute={mute}
      videoType={videoType}
      proportion={proportion}
    />
  );
};

class VideoBanner {
  constructor() {
    this.initVideBannerSlider();
    // need to append after init to make sure have target to insert
    this.append();
  }

  initVideBannerSlider() {
    if ($(".js-video-banner-slider").length === 0) return;

    $(".js-video-banner-slider").each((index, container) => {
      this.loadVideoBannerSlider($(container));
    });
  }

  loadVideoBannerSlider($container) {
    const isPagebuilderContainer = $container
      .parent()
      .hasClass(".pagebuilder-containers");
    const $videoBannerSlider = isPagebuilderContainer
      ? $container.children().first()
      : $container;
    $videoBannerSlider.on("init beforeChange", function () {
      let $nextSlide;

      // Use beforeChange event to change the color before changing to avoid the delay.
      // beforeChange
      // Arguments: event, slick, currentSlide, nextSlide
      const nextSlideIndex = arguments[3];
      if (nextSlideIndex != null) {
        $nextSlide = $videoBannerSlider.find(
          `.slick-slide[data-slick-index="${nextSlideIndex}"]`
        );
      } else {
        $nextSlide = $videoBannerSlider.find(".slick-active");
      }

      const activeContent = $nextSlide.find(".content");

      const windowSize = window.innerWidth;
      const isMobile = windowSize < 768 && windowSize >= 320;
      const isWhite =
        activeContent.data((isMobile ? "sp" : "pc") + "-header-color") ===
        "White";

      setHeaderColor($videoBannerSlider, isWhite);

      $videoBannerSlider
        .find(".slick-dots")
        .toggleClass("slick-dots--white", isWhite);
    });

    // init need to be trigger after listener
    $container.slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 10000,
      speed: 1000,
      slide: ".video-banner-slider--slide",
      rows: 0, // https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting/57620688#57620688
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            arrows: true,
          },
        },
      ],
    });
  }

  append() {
    if (document.querySelectorAll(".banner-video")) {
      const bannerVideos = document.querySelectorAll(".banner-video");
      bannerVideos.forEach((videoBanner, index) => {
        const target = videoBanner.querySelector(".videoTarget");

        return ReactDOM.render(
          <VideoBannerAPP videoBanner={videoBanner} index={index} />,
          target
        );
      });
    }
  }
}

const BannerAPP = ({ videoBanner, device, index }) => {
  let target = $(videoBanner).find(`.${device}`);
  let { videoSrc, videoId, imgSrc, aspectRation, mute, autoplay, proportion } =
    getVideoInfoFromDom(target);

  if (!(videoSrc || imgSrc)) {
    let placeHolderDevice =
      device === "pc" ? "sp" : device === "sp" ? "pc" : "pc";
    target = $(videoBanner).find(`.${placeHolderDevice}`);

    const newTargetSource = getVideoInfoFromDom(target);
    if (newTargetSource) {
      videoSrc = newTargetSource.videoSrc;
      videoId = newTargetSource.videoId;
      imgSrc = newTargetSource.imgSrc;
      aspectRation = newTargetSource.aspectRation;
      mute = newTargetSource.mute;
      autoplay = newTargetSource.autoplay;
    }
  }

  $(videoBanner)
    .find(".banner-chunk__info-wrapper")
    .removeAttr(`data-${device}-position`);

  return videoSrc || imgSrc ? (
    <div
      className={`${device}-${index} video-container`}
      style={{
        backgroundImage: `${imgSrc ? `url(${imgSrc}` : "initial"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: device === "pc" ? "45%" : aspectRation,
      }}
    >
      {videoSrc ? (
        <VideoLink
          videoUrl={videoSrc}
          videoId={videoId}
          mute={mute}
          autoplay={autoplay}
          device={device}
          key={device}
          index={index}
          images={imgSrc}
          proportion={proportion}
        />
      ) : null}
    </div>
  ) : null;
};

const VideoBannerAPP = ({ videoBanner, index }) => {
  const [device, setDevice] = React.useState(undefined);

  React.useEffect(() => {
    const win = $(window);
    getWindowSize();
    win.on("resize", getWindowSize);
  }, []);

  const getWindowSize = () => {
    const windowSize = window.innerWidth;

    if (windowSize < 768 && windowSize >= 320) {
      setDevice("sp");
    } else {
      setDevice("pc");
    }
  };

  if (device === undefined) {
    return null;
  }

  return (
    <BannerAPP
      videoBanner={videoBanner}
      device={device}
      key={device}
      index={index}
    />
  );
};

export default VideoBannerAPP;

export { VideoBanner };
