import * as React from "react";

import { CheckboxInput } from "../shared/CheckboxInput";
import { Input } from "../shared/Input";
import { Errors } from "../shared/Errors";

import { checkoutForm } from "../../orders";

import { Form, FieldProps } from "../../form";
import { getCreditCardError } from "../shared/getCreditCardError";

import { UploadSavePayment } from "../../gtm/GA";

import {
  _getExpiryYearOptions as getExpiryYearOptions,
  _getExpiryMonthOptions as getExpiryMonthOptions,
} from "../../components/AccountApp/Personal/CreditCards/CreditCardForm";
import { Selector } from "../shared/Selector";

const input = Form.on(checkoutForm, "form");
const isSaveCreditCardForm = Form.on(input, "isSaveCreditCard");
const creditCardInputForm = Form.on(input, "creditCardInput");

const expiryYearForm = Form.on(creditCardInputForm, "expiryYear");
const expiryMonthFrom = Form.on(creditCardInputForm, "expiryMonth");
const cvcFrom = Form.on(creditCardInputForm, "cvc");

const getNewOptions = (obj: any) =>
  obj.map(([key, name]: any) => ({
    key,
    name,
  }));

const CVCInput = ({
  value,
  updateInput,
  touchInput,
  dirty,
  errors,
  onClick,
}: FieldProps<string, any>) => {
  const { msg } = getCreditCardError(
    errors,
    (value || "").replace(/[^\d]/g, "")
  );

  return (
    <div className="cvc-field mb-3" onClick={onClick}>
      <Input
        type="tel"
        label="セキュリティー番号"
        value={value ? value.replace(/[^\d]/g, "") : ""}
        placeholder="例）123"
        maxLength={4}
        updateInput={updateInput}
        touchInput={touchInput}
        dirty={dirty}
        errors={errors}
        msg={msg}
      />
    </div>
  );
};
interface CreditCardInputProps {
  cvcClick?: () => void;
  newCreditCard: boolean;
  setNewCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreditCardInput = ({
  cvcClick,
  newCreditCard,
  setNewCreditCard,
}: CreditCardInputProps) => (
  <input.on
    field="creditCardInput"
    key={String(newCreditCard)}
    component={({ value }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (Object.entries(value || {}).length > 0) {
          setNewCreditCard(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      if (!newCreditCard) {
        return null;
      }

      return (
        <div className="credit-card-form">
          <div className="form-group">
            <creditCardInputForm.on
              field="name"
              component={(props) => (
                <Input
                  placeholder="例）TARO YAMADA"
                  {...props}
                  label="カード名義（ローマ字）"
                />
              )}
            />
            <creditCardInputForm.field
              component={({ errors }) => {
                const { responseField: errorResponseField, msg } =
                  getCreditCardError(errors);

                return errorResponseField === "cardholder_name" ? (
                  <Errors errors={errors} msg={msg} />
                ) : null;
              }}
            />
          </div>
          <div className="form-group">
            <creditCardInputForm.on
              field="number"
              component={(props) => (
                <Input
                  {...props}
                  label="クレジットカード番号"
                  name="credit-card"
                  required
                  type="tel"
                  placeholder="例）4242 4242 4242 4242"
                />
              )}
            />
            <creditCardInputForm.field
              component={({ errors }) => {
                const { responseField: errorResponseField, msg } =
                  getCreditCardError(errors);

                return errorResponseField === "card_number" ? (
                  <Errors errors={errors} msg={msg} />
                ) : null;
              }}
            />
          </div>
          <div className="form-group grid-row align-end">
            <div>
              <span className="input-label mt-15">有効期限</span>
              <div className="grid-row-2-only">
                <expiryMonthFrom.field
                  component={(props) => (
                    <div className="expire-month-field">
                      <Selector
                        {...props}
                        id="month"
                        htmlFor="month"
                        label="月"
                        name="month"
                        options={getNewOptions(getExpiryMonthOptions())}
                        msg={getCreditCardError(props.errors).msg}
                        maxLength={2}
                        placeholder={{
                          value: "",
                          coveredValue: "月",
                          position: 0,
                        }}
                      />
                    </div>
                  )}
                />
                <expiryYearForm.field
                  component={(props) => (
                    <div className="expire-year-field">
                      <Selector
                        {...props}
                        id="year"
                        label="年"
                        maxLength={4}
                        options={getNewOptions(getExpiryYearOptions())}
                        msg={getCreditCardError(props.errors).msg}
                        placeholder={{
                          value: "",
                          coveredValue: "年",
                          position: 0,
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <cvcFrom.field
              component={(props) => <CVCInput {...props} onClick={cvcClick} />}
            />
          </div>
          <div className="form-group row">
            <creditCardInputForm.field
              component={({ errors }) => {
                const { responseField: errorResponseField, msg } =
                  getCreditCardError(errors);

                return errorResponseField === "card_valid_term" ? (
                  <Errors errors={errors} msg={msg} />
                ) : null;
              }}
            />
          </div>
          <div className="form-group">
            <isSaveCreditCardForm.field
              component={({ value, updateInput, touchInput }) => (
                <>
                  <CheckboxInput
                    checked={value || false}
                    onChange={(checked) => {
                      updateInput(checked);
                      touchInput();
                      UploadSavePayment(checked);
                    }}
                  >
                    <span>クレジットカード情報を保存</span>
                  </CheckboxInput>
                </>
              )}
            />
          </div>
          <creditCardInputForm.field
            component={({ errors }) => {
              const { responseField: errorResponseField, msg } =
                getCreditCardError(errors);

              if (
                errorResponseField === "card_number" ||
                errorResponseField === "cardholder_name" ||
                errorResponseField === "card_conf_number" ||
                errorResponseField === "card_valid_term"
              ) {
                return null;
              }

              return <Errors errors={errors} msg={msg} />;
            }}
          />
        </div>
      );
    }}
  />
);

export { CreditCardInput, CVCInput };
