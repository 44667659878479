import { proto } from "../../proto";

const defaultMessage = {
  "2001":
    "こちらのカードは現在ご利用できません。カード発行会社にお問い合わせください。",
  "2002": "システムエラーです。しばらくたってから再度お試しください。",
  "2003": "カード情報をご確認の上、再度ご入力ください。"
};

const creditCardErrorMap = {
  "2003": [
    {
      response_detail: "1G44", // nampu credit card cvc 1, length <3
      response_msg: "コードが違います。"
    },
    {
      response_detail: "1G45", // nampu credit card cvc 111, invalid cvc
      response_msg: "コードが違います。"
    }
  ]
};

const getCreditCardError = (
  fieldErrors: proto.ValidationError.IFieldViolation[],
  cvc?: string
): {
  responseCode: string;
  responseField: string;
  msg?: string;
} => {
  let responseCode = "",
    responseDetail = "",
    responseField = "",
    responseMsg = undefined;

  if (fieldErrors && fieldErrors.length > 0) {
    responseMsg = fieldErrors[0].msg || undefined;

    if (responseMsg) {
      const errorMsgs = responseMsg.split("#") || [];
      if (errorMsgs && errorMsgs.length > 0) {
        responseCode = errorMsgs[0];
        responseDetail = errorMsgs[1];
        if (responseDetail) {
          const fieldMsgs = responseDetail.split('"');
          if (fieldMsgs && fieldMsgs.length > 2) {
            responseField = fieldMsgs[1];
            responseMsg = fieldMsgs[2];
          } else {
            responseMsg = responseDetail;
          }
        } else {
          responseMsg = undefined;
        }
      }
    }

    if (
      fieldErrors[0].field === "Cvc" ||
      fieldErrors[0].field === "Form.CreditCardInput.Cvc"
    ) {
      responseCode = fieldErrors[0].field;
    }
  }

  let msg = responseMsg;
  if (responseCode) {
    const matched = (creditCardErrorMap[responseCode as "2003"] || []).find(
      ({ response_detail }: { response_detail?: string }) => {
        return response_detail === responseField;
      }
    );

    msg = matched ? matched.response_msg : responseMsg;
    if (
      responseCode === "2001" ||
      responseCode === "2002" ||
      responseCode === "2003"
    ) {
      msg = msg ? msg : defaultMessage[responseCode as "2001"];
    } else {
      if (
        responseCode === "Cvc" ||
        responseCode === "Form.CreditCardInput.Cvc"
      ) {
        if (fieldErrors[0] && fieldErrors[0].code) {
          responseField = fieldErrors[0].code;
        }
        msg = "コードが違います。";
        if (!cvc || cvc === "") {
          msg = "入力してください。";
        }
      }
    }

    switch (responseField) {
      case "cardholder_name":
        msg = "カード名義は半角英字で入力してください。";
        break;
      case "card_number":
        if (msg === "パラメータの値が設定されていません。")
          msg = "入力してください。";
        if (msg === "パラメータの値は桁数が不正です。")
          msg = "カード番号に誤りがあります。";
        break;
      case "card_conf_number":
        if (msg === "パラメータの値が設定されていません。") {
          msg = "入力してください。";
        } else {
          msg = "コードが違います。";
        }

        break;
      case "card_valid_term":
        if (msg === "パラメータの値は不正な値です。")
          msg = "カードの有効期限をご確認ください。";
        break;
      default:
        break;
    }
  }

  switch (responseCode) {
    case "cvc code must less then 4 and greater then 3":
      responseField = "card_conf_number";
      msg = "コードが違います。";
      if (!cvc || cvc === "") {
        msg = "入力してください。";
      }
      break;
    case "expire month or expire year is required":
      responseField = "card_valid_term";
      msg = "カードの有効期限をご確認ください。";
      break;
    default:
      break;
  }

  return { responseCode, responseField, msg };
};

export { getCreditCardError };
