import $ from "jquery";

// Changes based on https://github.com/theplant/asics3/blob/15a0741d5ec2cee1ee9adee0d1fb0e094975bf36/app/views/qor/assets/javascripts/video.js#L7-L8
// https://youtu.be/IZC02EQqcXc
// https://www.youtube.com/embed/IZC02EQqcXc
const reUrlYoutube =
  /* eslint-disable-next-line */
  /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com\S*[^\w\-\s])([\w\-]{11})(?=[^\w\-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
// https://player.vimeo.com/video/219364649
// https://vimeo.com/179473410
const reUrlVimeo =
  /https?:\/\/(?:[0-9a-z-]+\.)?vimeo.com\/(?:\w+\/)?(\d+)($|\/)/;
// http://player.freecaster.com/embed/1215043
const relUrlFreecaster =
  /https?:\/\/(?:[0-9a-z-]+\.)?freecaster.com\/(?:\w+\/)?(\d+)($|\/)/;

const normalVideo = "normalVideo";

const changePlainLinkToEmbed = (videoUrl: string) => {
  let videoSrc: string | undefined = videoUrl;
  let videoType: string | undefined = normalVideo;

  if (videoUrl.match(reUrlYoutube)) {
    videoSrc = videoUrl.replace(reUrlYoutube, "www.youtube.com/embed/$1");
    videoType = "youtubeVideo";
  } else if (videoUrl.match(reUrlVimeo)) {
    videoSrc = videoUrl.replace(reUrlVimeo, "player.vimeo.com/video/$1");
    videoType = "vimeoVideo";
  } else if (videoUrl.match(relUrlFreecaster)) {
    videoSrc = videoUrl.replace(
      relUrlFreecaster,
      "player.freecaster.com/embed/$1"
    );
    videoType = "freecasterVideo";
  }

  return {
    url: videoSrc.indexOf("https") === -1 ? "https://" + videoSrc : videoSrc,
    videoType,
  };
};

const getAspectRatio = (proportion) => {
  let arr = proportion.split(":");

  // return hegiht / width

  if (arr.length === 2) {
    return (parseInt(arr[1]) / parseInt(arr[0])) * 100 + "%";
  }

  return (9 / 16) * 100 + "%";
};

const getVideoID = (videoUrl) => {
  let videoId;
  if (videoUrl.split("/").length > 0) {
    videoId = videoUrl.split("/").pop();
    if (videoId && videoId.split("?") && videoId?.split("?").length > 0) {
      videoId = videoId?.split("?")[0];
    }
  }

  return videoId;
};

const getVideoInfoFromDom = (dom) => {
  const videoSrc = $(dom).attr("data-video-src");
  const imgSrc = $(dom).attr("data-img-src");
  const proportion = $(dom).attr("data-proportion");
  const mute = $(dom).attr("data-mute") === "true";
  const autoplay = $(dom).attr("data-autoplay") === "true";
  const videoId = videoSrc ? getVideoID(videoSrc) : undefined;

  const aspectRation = proportion ? getAspectRatio(proportion) : undefined;

  return {
    videoSrc,
    imgSrc,
    aspectRation,
    mute,
    autoplay,
    videoId,
    proportion,
  };
};

export { changePlainLinkToEmbed, getVideoInfoFromDom, normalVideo };
